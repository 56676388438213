<template>
	<v-container class="h-100 .pb-55 mw-100 px-0">
		<v-row class="mx-0">
			<v-col md="12" class="py-0">
				<v-row style="min-height: 46px">
					<v-col cols="8" class="my-auto d-flex align-center">
						<template v-for="(row, index) in breadcrumbs">
							<div
								:key="`bread-crumb-text-${index}`"
								v-on:click="breadcrumb_route(row)"
								class="listing-breadcrumb text-truncate"
								:class="{
									'blue--text text--darken-4': index == breadcrumbs.length - 1,
									'grey--text text--darken-1 cursor-pointer': !(index == breadcrumbs.length - 1),
								}"
								style="max-width: 200px"
							>
								{{ row.text }}
							</div>
							<v-icon
								:key="`bread-crumb-icon-${index}`"
								:class="{
									'blue--text text--darken-4': index == breadcrumbs.length - 1,
									'grey--text text--darken-1': !(index == breadcrumbs.length - 1),
								}"
								class="mx-1"
								v-if="breadcrumbs[index + 1]"
								>mdi-chevron-right</v-icon
							>
						</template>
					</v-col>
					<v-col cols="4" class="drive-action-btn text-right">
						<template v-if="selected && context_menu.length">
							<template v-for="(row, index) in context_menu">
								<v-tooltip v-if="row.action != 'change-color'" top :key="index">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-bind="attrs"
											v-on="on"
											:key="index"
											:disabled="loading || detail_loading"
											v-on:click="do_menu_action(row.action)"
											class="mx-2"
											color="blue darken-4"
											icon
										>
											<v-icon>{{ row.icon }}</v-icon>
										</v-btn>
									</template>
									<span>{{ row.name }}</span>
								</v-tooltip>
							</template>
						</template>
					</v-col>
				</v-row>
			</v-col>
			<v-col md="12" class="py-0">
				<v-layout class="border-top">
					<v-flex
						:style="`position: relative; width: calc(100% - ${pageWidth}); min-width: calc(100% - ${pageWidth})`"
					>
						<div
							style="max-height: calc(100vh - 125px); overflow: auto; padding-bottom: 60px"
							id="scrollable-div"
						>
							<div class="table-header">
								<v-layout>
									<v-flex class="my-auto font-level-3-bold pl-1" lg4 md4 sm4>
										<v-icon class="mr-2">mdi-format-list-text</v-icon>
										Name</v-flex
									>
									<v-flex
										class="my-auto font-level-3-bold"
										lg3
										md3
										sm3
										style="max-width: calc(100vh - 265px)"
										>Tags</v-flex
									>
									<v-flex class="my-auto font-level-3-bold text-center" lg1 md1 sm1>Owner</v-flex>
									<v-flex class="my-auto font-level-3-bold text-center" lg2 md2 sm2>Modified</v-flex>
									<v-flex class="my-auto font-level-3-bold text-center" lg2 md2 sm2>File Size</v-flex>
								</v-layout>
								<v-progress-linear
									v-if="loading"
									indeterminate
									color="blue darken-4"
									class="drive-listing-loader"
								></v-progress-linear>
							</div>
							<template v-if="rows.length">
								<v-layout
									v-on:click="select_row(row)"
									v-on:dblclick="open_folder(row)"
									class="row-file cursor-pointer light-border-top py-1 px-2"
									v-for="(row, index) in rows"
									:key="index"
									:class="{ 'row-selected': selected == row.uuid, 'row-disabled': loading }"
									v-on:contextmenu="context_menu_dialog($event, row)"
									:style="`color: ${row.color_code}; transition: 0.4s ease-in-out`"
								>
									<v-flex class="text-truncate my-auto font-level-3 d-flex align-items-center" lg4 md4 sm4>
										<v-icon v-if="row.type == 'folder'" large :style="`color: ${row.color_code}`">
											<template v-if="row.locked">mdi-folder-lock</template>
											<template v-else>mdi-folder</template>
										</v-icon>
										<div v-else class="file-icon small-icon py-2">
											<inline-svg :src="$assetURL(`media/mime/${row.extension}.svg`)" />
										</div>
										<show-value class="ml-2 ellipses" :object="row" object-key="name" label="name" />
										<v-chip color="green" text-color="white" class="mx-2 text-capitalize" v-if="row.public">
											Public
										</v-chip>
										<i
											aria-hidden="true"
											class="ml-2 v-icon mdi mdi-star theme--light"
											:style="`color: ${row.color_code}; font-size: 16px;`"
											v-if="row.starred"
										></i>
										<i
											aria-hidden="true"
											class="ml-2 v-icon mdi mdi-account-group theme--light"
											:style="`color: ${row.color_code}; font-size: 16px;`"
											v-if="row.sharred"
										></i>
									</v-flex>
									<v-flex
										class="text-truncate my-auto font-level-3"
										lg3
										md3
										sm3
										style="max-width: calc(100vh - 265px)"
										:style="`color: ${row.color_code}`"
									>
										<template v-if="row.tag_arr && row.tag_arr.length">
											<v-chip
												v-for="(tag, index) in row.tag_arr"
												:key="index"
												:color="tag.color"
												text-color="white"
												class="mr-2 text-capitalize"
											>
												{{ tag.text }}
											</v-chip>
										</template>
										<template v-else>
											<show-value :object="row" object-key="tags" label="tags" />
										</template>
									</v-flex>
									<v-flex class="text-truncate my-auto font-level-3 text-center" lg1 md1 sm1>
										<show-value :object="row" object-key="owner" label="owner" />
									</v-flex>
									<v-flex class="text-truncate my-auto font-level-3 text-center" lg2 md2 sm2>
										<show-value :object="row" object-key="modified.human_format" label="modified" />
									</v-flex>
									<v-flex class="text-truncate my-auto font-level-3 text-center" lg2 md2 sm2>
										<show-value
											v-if="row.type == 'file'"
											:object="row"
											object-key="formatted_size"
											label="file size"
										/>
										<em class="text-muted" v-else> - </em>
									</v-flex>
								</v-layout>
							</template>
							<template v-else>
								<v-layout v-if="!loading">
									<v-flex class="my-auto font-level-3-bold py-3 text-center">No matching results</v-flex>
								</v-layout>
							</template>

							<v-menu
								v-model="show_context_menu"
								:position-x="menu_x"
								:position-y="menu_y"
								absolute
								offset-y
								z-index="9"
							>
								<v-list class="context-menu-list">
									<template v-for="(item, index) in context_menu">
										<v-menu
											v-if="item.action == 'change-color'"
											:key="`list-child-item-${index}`"
											open-on-hover
											bottom
											min-width="200"
											right
											offset-x
										>
											<template v-slot:activator="{ on, attrs }">
												<v-list-item link v-bind="attrs" v-on="on">
													<v-list-item-icon>
														<v-icon>{{ item.icon }}</v-icon>
													</v-list-item-icon>
													<v-list-item-title class="font-level-1-bold">{{ item.name }}</v-list-item-title>
													<v-list-item-icon class="m-0 ml-2">
														<v-icon>mdi-chevron-right</v-icon>
													</v-list-item-icon>
												</v-list-item>
											</template>
											<div class="cm-context-sub-menu bg-white" style="width: 200px; background: #fff">
												<table width="100%">
													<tr>
														<td v-on:click="update_color('#5f6368')" style="background-color: #5f6368">
															<span v-if="temp_file.color_code == '#5f6368'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#92e1c0')" style="background-color: #92e1c0">
															<span v-if="temp_file.color_code == '#92e1c0'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#42d692')" style="background-color: #42d692">
															<span v-if="temp_file.color_code == '#42d692'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#ac725e')" style="background-color: #ac725e">
															<span v-if="temp_file.color_code == '#ac725e'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
													</tr>
													<tr>
														<td v-on:click="update_color('#d06b64')" style="background-color: #d06b64">
															<span v-if="temp_file.color_code == '#d06b64'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#16a765')" style="background-color: #16a765">
															<span v-if="temp_file.color_code == '#16a765'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#9fe1e7')" style="background-color: #9fe1e7">
															<span v-if="temp_file.color_code == '#9fe1e7'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#cabdbf')" style="background-color: #cabdbf">
															<span v-if="temp_file.color_code == '#cabdbf'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
													</tr>
													<tr>
														<td v-on:click="update_color('#f83a22')" style="background-color: #f83a22">
															<span v-if="temp_file.color_code == '#f83a22'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#7bd148')" style="background-color: #7bd148">
															<span v-if="temp_file.color_code == '#7bd148'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#9fc6e7')" style="background-color: #9fc6e7">
															<span v-if="temp_file.color_code == '#9fc6e7'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#cca6ac')" style="background-color: #cca6ac">
															<span v-if="temp_file.color_code == '#cca6ac'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
													</tr>
													<tr>
														<td v-on:click="update_color('#fa573c')" style="background-color: #fa573c">
															<span v-if="temp_file.color_code == '#fa573c'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#b3dc6c')" style="background-color: #b3dc6c">
															<span v-if="temp_file.color_code == '#b3dc6c'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#4986e7')" style="background-color: #4986e7">
															<span v-if="temp_file.color_code == '#4986e7'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#f691b2')" style="background-color: #f691b2">
															<span v-if="temp_file.color_code == '#f691b2'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
													</tr>
													<tr>
														<td v-on:click="update_color('#ff7537')" style="background-color: #ff7537">
															<span v-if="temp_file.color_code == '#ff7537'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#fbe983')" style="background-color: #fbe983">
															<span v-if="temp_file.color_code == '#fbe983'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#9a9cff')" style="background-color: #9a9cff">
															<span v-if="temp_file.color_code == '#9a9cff'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#cd74e6')" style="background-color: #cd74e6">
															<span v-if="temp_file.color_code == '#cd74e6'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
													</tr>
													<tr>
														<td v-on:click="update_color('#ffad46')" style="background-color: #ffad46">
															<span v-if="temp_file.color_code == '#ffad46'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#fad165')" style="background-color: #fad165">
															<span v-if="temp_file.color_code == '#fad165'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#b99aff')" style="background-color: #b99aff">
															<span v-if="temp_file.color_code == '#b99aff'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
														<td v-on:click="update_color('#a47ae2')" style="background-color: #a47ae2">
															<span v-if="temp_file.color_code == '#a47ae2'">
																<v-icon color="white">mdi-check</v-icon>
															</span>
															<template v-else>&nbsp;</template>
														</td>
													</tr>
												</table>
											</div>
										</v-menu>
										<v-list-item v-else :key="`list-item-${index}`" v-on:click="do_menu_action(item.action)">
											<v-list-item-icon>
												<v-icon>{{ item.icon }}</v-icon>
											</v-list-item-icon>
											<v-list-item-title class="font-level-1-bold">{{ item.name }}</v-list-item-title>
										</v-list-item>
									</template>
								</v-list>
							</v-menu>
						</div>
						<v-col md="12" class="pagination-wrapper" v-if="pagination.total_pages > 1">
							<v-pagination
								:disabled="loading || detail_loading"
								color="blue darken-4"
								v-model="pagination.current_page"
								v-on:input="update_pagination($event)"
								:length="pagination.total_pages"
								:total-visible="7"
							></v-pagination>
						</v-col>
					</v-flex>
					<v-flex
						v-if="detail_drawer"
						md3
						sm3
						class="border-left-light-grey"
						:style="`width: ${pageWidth}; min-width: ${pageWidth}`"
					>
						<table width="100%" style="table-layout: fixed">
							<tr>
								<td width="15%" align="center">
									<div class="file-icon x-medium-icon py-2">
										<v-skeleton-loader
											type="avatar"
											width="36"
											height="36"
											v-if="detail_loading"
										></v-skeleton-loader>
										<template v-else>
											<inline-svg
												v-if="file.type == 1"
												height="36px"
												:src="$assetURL(`media/mime/${file.extension}.svg`)"
											/>
											<v-icon v-else large style="line-height: 1 !important">mdi-folder</v-icon>
										</template>
									</div>
								</td>
								<td width="75%" class="font-level-5-bold pl-1">
									<v-skeleton-loader type="sentences" v-if="detail_loading"></v-skeleton-loader>
									<div v-else class="d-flex align-items-center">
										<p class="mb-0 text-truncate">{{ file.name }}</p>
										<v-chip
											color="green"
											text-color="white"
											class="mx-2 text-capitalize"
											v-if="file.is_public"
										>
											Public
										</v-chip>
									</div>
								</td>
								<td width="10%" align="center">
									<v-icon v-on:click="detail_drawer = false" :disabled="detail_loading">mdi-close</v-icon>
								</td>
							</tr>
						</table>
						<v-tabs fixed-tabs v-model="tab">
							<v-tab :disabled="detail_loading" href="#detail" class="font-level-3-bold">Details</v-tab>
							<v-tab
								:disabled="detail_loading"
								href="#activity"
								class="font-level-3-bold"
								v-on:click="get_file_log()"
								>Activity</v-tab
							>
							<v-tab
								:disabled="detail_loading"
								v-if="false"
								href="#version_control"
								class="font-level-3-bold"
								>Version Control</v-tab
							>
						</v-tabs>
						<v-tabs-items v-model="tab">
							<v-tab-item value="detail">
								<div class="detail-info-tab-content">
									<div class="file-image mx-3">
										<div class="file-icon large-icon p-4 d-flex">
											<v-skeleton-loader
												type="avatar"
												width="120"
												height="120"
												v-if="detail_loading"
											></v-skeleton-loader>
											<template v-else>
												<inline-svg
													v-if="file.type == 1"
													:src="$assetURL(`media/mime/${file.extension}.svg`)"
												/>
												<v-icon v-else x-large style="font-size: 120px; line-height: 1 !important"
													>mdi-folder</v-icon
												>
											</template>
										</div>
									</div>
									<div class="access-wrapper mb-5 mx-3">
										<div class="h4 py-2 border-bottom">Who has access</div>

										<div class="share-with px-3 my-5">
											<v-skeleton-loader
												type="avatar"
												width="60"
												height="60"
												v-if="detail_loading"
											></v-skeleton-loader>
											<template v-else>
												<v-tooltip top>
													<template v-slot:activator="{ on, attrs }">
														<v-avatar class="mr-2" size="60" v-bind="attrs" v-on="on">
															<v-img
																v-if="file.owner.profile"
																contain
																:src="file.owner.profile"
																:alt="file.owner.display_name"
															></v-img>
															<v-icon v-else dark> mdi-account-circle </v-icon>
														</v-avatar>
													</template>
													<span>{{ file.owner.display_name }} is the owner</span>
												</v-tooltip>
												<span></span>
												<v-tooltip v-for="(row, index) in file.shared" :key="index" top>
													<template v-slot:activator="{ on, attrs }">
														<v-avatar class="mr-2" size="60" v-bind="attrs" v-on="on">
															<v-img contain v-if="row.profile" :src="row.profile" :alt="row.display_name"></v-img>
															<v-icon v-else dark> mdi-account-circle </v-icon>
														</v-avatar>
													</template>
													<span>{{ row.display_name }}</span>
												</v-tooltip>
											</template>
										</div>
										<v-btn
											text
											color="blue darken-4"
											class="bold-600"
											:disabled="loading || detail_loading"
											v-on:click="do_menu_action('share')"
											>MANAGE ACCESS</v-btn
										>
										<div v-if="false" class="not-share-with px-3 my-5">
											<v-icon class="mr-3">mdi-account-cancel-outline</v-icon>
											<span>Not shared</span>
										</div>
									</div>
									<div class="access-wrapper mb-5 mx-3">
										<div class="h4 py-2 border-bottom">Tags</div>
										<div v-if="file.tags && file.tags.length" class="share-with px-3 mt-5 mb-4">
											<div class="d-flex flex-wrap">
												<template v-if="detail_loading">
													<v-skeleton-loader
														v-for="skl in 2"
														:key="skl"
														type="chip"
														class="mr-3"
													></v-skeleton-loader>
												</template>
												<template v-else>
													<v-chip
														class="mr-2 mb-2"
														v-for="(tag, index) in file.tags"
														:key="index"
														:color="tag.color"
														text-color="white"
														>{{ tag.text }}</v-chip
													>
												</template>
											</div>
										</div>
										<div v-else>
											<p class="mb-0 py-4 px-2">Oops...! No Tags Found.</p>
										</div>
										<v-btn
											text
											color="blue darken-4"
											class="bold-600"
											:disabled="loading || detail_loading"
											v-on:click="do_menu_action('manage-tags')"
											>MANAGE TAGS</v-btn
										>
									</div>
									<div v-if="file.set_reminder" class="system-praperties-wrapper mx-3 mt-5 mb-4">
										<div class="h4 py-2 border-bottom">Reminder</div>
										<div class="system-info px-3">
											<table width="100%" style="table-layout: fixed">
												<tr>
													<td width="30%">Reminder Type</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value
															v-else
															:object="file"
															object-key="reminder_type_text"
															label="Reminder Type"
														/>
													</td>
												</tr>
												<tr>
													<td width="30%">Start Date</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value
															v-else
															:object="file"
															object-key="reminder_start_date_formatted"
															label="Start Date"
														/>
													</td>
												</tr>
												<tr v-if="file.reminder_frequency > 0">
													<td width="30%">Frequency</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value v-else :object="file" object-key="reminder_frequency" label="Frequency" />
													</td>
												</tr>
												<tr v-if="file.reminder_end_date">
													<td width="30%">End Date</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value
															v-else
															:object="file"
															object-key="reminder_end_date_formatted"
															label="End Date"
														/>
													</td>
												</tr>
											</table>
										</div>
									</div>
									<div class="system-praperties-wrapper mx-3 mt-5 mb-4">
										<div class="h4 py-2 border-bottom">Detail</div>
										<div class="system-info px-3">
											<table width="100%" style="table-layout: fixed">
												<tr>
													<td width="30%">{{ file.type == 2 ? "Folder" : "File" }} #</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value v-else :object="file" object-key="barcode" label="#" />
													</td>
												</tr>
												<tr>
													<td width="30%">Public</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value v-else :object="file" object-key="is_public_text" label="Public" />
													</td>
												</tr>
												<tr>
													<td width="30%">Reference No</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value v-else :object="file" object-key="reference" label="reference no" />
													</td>
												</tr>
												<tr v-if="file.start_date">
													<td width="30%">Start Date</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value
															v-else
															:object="file"
															object-key="start_date_formatted"
															label="start date"
														/>
													</td>
												</tr>
												<tr v-if="file.expiry_date">
													<td width="30%">Expiry Date</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value
															v-else
															:object="file"
															object-key="expiry_date_formatted"
															label="expiry date"
														/>
													</td>
												</tr>
												<tr>
													<td width="30%">Name</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value v-else :object="file" object-key="name" label="name" />
													</td>
												</tr>
												<tr>
													<td width="30%">Type</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value v-else :object="file" object-key="extension" label="type" />
													</td>
												</tr>
												<tr>
													<td width="30%">Location</td>
													<td width="70%" class="text-lowercase">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value v-else :object="file" object-key="location" label="location" />
													</td>
												</tr>
												<tr>
													<td width="30%">Owner</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value v-else :object="file" object-key="owner.display_name" label="owner" />
													</td>
												</tr>
												<tr>
													<td width="30%">Modified</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value v-else :object="file" object-key="modified_at" label="modified" />
													</td>
												</tr>
												<tr>
													<td width="30%">Created</td>
													<td width="70%">
														<v-skeleton-loader type="text" v-if="detail_loading"></v-skeleton-loader>
														<show-value v-else :object="file" object-key="created_at" label="created" />
													</td>
												</tr>
												<tr>
													<td width="100%" colspan="2" style="padding-right: unset">
														<table width="100%">
															<tr>
																<td width="85%" style="padding-right: unset">
																	<text-area
																		ref="file-description"
																		:class="{ 'file-description-editing': !d_focus }"
																		:readonly="!d_focus"
																		v-on:change="update_description()"
																		v-model="file_description"
																		placeholder="Add a description..."
																		style="width: 100%"
																		:rows="4"
																		auto-grow
																	></text-area>
																</td>
																<td
																	valign="top"
																	width="15%"
																	style="padding-right: unset"
																	class="pt-6"
																	align="center"
																>
																	<v-btn
																		v-if="!d_focus"
																		v-on:click="focus_description()"
																		:disabled="loading || detail_loading"
																		small
																		icon
																		width="40px"
																		height="40px"
																	>
																		<v-icon> mdi-pencil </v-icon>
																	</v-btn>
																</td>
															</tr>
														</table>
													</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
							</v-tab-item>
							<v-tab-item value="activity">
								<div class="detail-activity-tab-content">
									<div class="activity-details-wrapper px-3 mx-3">
										<div class="mt-5" v-if="log_loading">
											<v-skeleton-loader
												v-for="(row, index) in 3"
												:key="index"
												type="heading, list-item-avatar-three-line"
											></v-skeleton-loader>
										</div>
										<template v-else>
											<div class="h4 py-2 border-bottom" v-if="isNotEmpty(file_log)">
												<div
													v-for="(row, index) in file_log"
													:key="`activity-log-${index}`"
													class="activity-info mb-5"
												>
													<div class="date my-3">{{ row.month }}</div>
													<v-flex
														class="d-flex cm-flex mb-4"
														v-for="(log, rindex) in row.rows"
														:key="`${index}-${rindex}`"
													>
														<v-img
															class="user-img mr-5"
															max-height="50"
															max-width="50"
															:src="log.created_by.profile"
														></v-img>
														<div class="d-flex flex-column flex-nowrap">
															<span v-html="log.description"></span>
															<span class="text--secondary mb-0 pt-1"
																>Date Time: {{ formatDateTime(log.added_at) }}</span
															>
															<span class="text--secondary mb-0"
																>IP Address: {{ log.ip_address }}
																<v-icon
																	small
																	class="ml-1 my-0 py-0"
																	color="blue darken-4"
																	v-on:click="redirect_ip(log.ip_address)"
																	>mdi-open-in-new</v-icon
																></span
															>
														</div>
													</v-flex>
												</div>
											</div>
											<div v-else>
												<p class="my-3 font-level-3-bold text-center">Oops... No Activity Found</p>
											</div>
										</template>
										<p v-if="false">No recorded activity before 4 April 2022</p>
									</div>
								</div>
							</v-tab-item>
							<v-tab-item v-if="false" value="version_control">
								<div class="version-control-tab px-3">
									<table>
										<thead>
											<tr>
												<th class="icon"></th>
												<th class="name">Name</th>
												<th class="version">Version</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td class="icon"><inline-svg :src="$assetURL('media/mime/pdf.svg')" /></td>
												<td class="name ellipses">
													<span class="fw-500 blue--text cursor-pointer">Service Report Format.pdf</span>
												</td>
												<td class="version ellipses">v01.00</td>
											</tr>
											<tr>
												<td class="icon"><inline-svg :src="$assetURL('media/mime/pdf.svg')" /></td>
												<td class="name ellipses">
													<span class="fw-500 blue--text cursor-pointer">Service Report Format.pdf</span>
												</td>
												<td class="version ellipses">v01.05</td>
											</tr>
										</tbody>
									</table>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</v-flex>
				</v-layout>
			</v-col>
		</v-row>
		<share-with-people
			:default-member="file.owner"
			:share-dialog="share_dialog"
			v-on:close="share_dialog = false"
			v-model="access_permission"
			v-on:submit="update_sharing($event)"
		></share-with-people>
		<delete-dialog
			:delete-dialog="delete_dialog"
			:to-be-delete="to_be_delete"
			v-on:close="delete_dialog = false"
			v-on:success="delete_success()"
		></delete-dialog>
		<rename-dialog
			:rename-dialog="rename_dialog"
			:to-be-rename="to_be_rename"
			v-on:close="rename_dialog = false"
			v-on:success="rename_success()"
		></rename-dialog>
		<move-dialog
			:move-dialog="move_dialog"
			:to-be-move="to_be_move"
			v-on:close="move_dialog = false"
			v-on:success="move_success()"
		></move-dialog>
		<lock-dialog
			:lock-dialog="lock_dialog"
			:to-be-lock="to_be_lock"
			v-on:close="lock_dialog = false"
			v-on:success="lock_success()"
		></lock-dialog>
		<unlock-dialog
			:unlock-dialog="unlock_dialog"
			:to-be-unlock="to_be_unlock"
			v-on:close="unlock_dialog = false"
			v-on:success="unlock_success()"
		></unlock-dialog>
		<unlock-folder-dialog
			:unlock-folder-dialog="folder_unlock_dialog"
			:folder-unlock="folder_unlock"
			v-on:close="folder_unlock_dialog = false"
			v-on:success="folder_unlock_success($event)"
		></unlock-folder-dialog>
		<manage-tag-dialog
			:tag-dialog="tag_dialog"
			v-on:close="tag_dialog = false"
			v-model="file_tags"
			v-on:submit="update_tags($event)"
		></manage-tag-dialog>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DriveMixin from "@/core/mixins/drive.mixin";
import TextArea from "@/view/components/TextAreaInput";

export default {
	mixins: [DriveMixin],
	data() {
		return {
			endpoint: "starred",
		};
	},
	components: {
		TextArea,
	},
	computed: {
		...mapGetters(["breadcrumbs"]),
	},
};
</script>
