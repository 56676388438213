<template>
	<div>
		<viewer-dialog :dialog="pdfDialog">
			<template v-slot:title>
				<v-layout class="align-center">
					<v-flex md8 class="text-left">
						{{ title }}
					</v-flex>
					<v-flex md4 class="text-right">
						<v-btn
							color="blue darken-4 white--text"
							:disabled="loading"
							depressed
							class="mr-2"
							tile
							v-on:click="$emit('download', true)"
							>Download</v-btn
						>
						<v-btn :disabled="loading" depressed class="mr-2" tile v-on:click="$emit('close', true)"
							>Close</v-btn
						>
					</v-flex>
				</v-layout>
			</template>
			<template v-slot:body>
				<div v-if="pdfDialog">
					<pdf-viewer
						:src="src"
						:page="page_no"
						v-on:page-loaded="update_page_no($event)"
						v-on:num-pages="update_total_page($event)"
					></pdf-viewer>
				</div>
			</template>
			<template v-slot:action>
				<v-layout v-if="total_page" class="align-center">
					<v-flex md6 class="text-left">
						<div class="h4 py-2">
							Showing Page <span>{{ page_no }}</span> of {{ total_page }}
						</div>
					</v-flex>
					<v-flex md6 class="text-right">
						<div class="py-2">
							<v-btn
								:disabled="is_disabled('prev')"
								depressed
								tile
								color="blue darken-4 white--text"
								v-on:click="prev_page()"
								>Prev</v-btn
							>
							<v-btn depressed tile color="blue darken-4" outlined class="mx-4">{{ page_no }}</v-btn>
							<v-btn
								:disabled="is_disabled('next')"
								depressed
								tile
								color="blue darken-4 white--text"
								v-on:click="next_page()"
								>Next</v-btn
							>
						</div>
					</v-flex>
				</v-layout>
			</template>
		</viewer-dialog>
	</div>
</template>

<script>
import PdfViewer from "vue-pdf";
import Dialog from "@/view/components/Dialog";

export default {
	name: "pdf-viewer-dialog",
	props: {
		pdfDialog: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		src: {
			type: String,
			default: null,
		},
		title: {
			type: String,
			default: null,
		},
	},
	watch: {
		pdfDialog() {
			this.page_no = 1;
			this.total_page = 0;
		},
	},
	data() {
		return {
			page_no: 1,
			total_page: 0,
		};
	},
	methods: {
		prev_page() {
			if (this.page_no > 1) {
				this.page_no = this.page_no - 1;
			}
		},
		next_page() {
			if (this.page_no < this.total_page) {
				this.page_no = this.page_no + 1;
			}
		},
		is_disabled(type) {
			if (this.loading) {
				return true;
			}

			if (type == "prev") {
				if (this.page_no < 2) {
					return true;
				}
			}
			if (type == "next") {
				if (this.page_no >= this.total_page) {
					return true;
				}
			}
			return false;
		},
		update_page_no(param) {
			this.page_no = param;
		},
		update_total_page(param) {
			this.total_page = param;
		},
		init() {},
	},
	components: {
		"viewer-dialog": Dialog,
		"pdf-viewer": PdfViewer,
	},
	mounted() {
		this.init();
	},
};
</script>
