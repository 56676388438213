import { QUERY, GET, PATCH } from "@/core/services/store/request.module";
import { SEARCH_ENABLED, SEARCH_TITLE } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ShareWithPeople from "@/view/components/ShareWithPeople";
import JwtService from "@/core/services/jwt.service";
import DeleteConfirmation from "@/view/components/DeleteConfirmation";
import RestoreConfirmation from "@/view/components/RestoreConfirmation";
import EmptyBinConfirmation from "@/view/components/EmptyBinConfirmation";
import ManageTagDialog from "@/view/components/ManageTagDialog";
import RenameDialog from "@/view/components/RenameDialog";
import ShowValue from "@/view/components/ShowValue";
import MoveDialog from "@/view/components/MoveDialog";
import LockDialog from "@/view/components/LockDialog";
import UnlockDialog from "@/view/components/UnlockDialog";
import UnlockFolderDialog from "@/view/components/UnlockFolderDialog";
import { find, toSafeInteger, last, head, toString, cloneDeep } from "lodash";
import { EventBus } from "@/core/event-bus/event.bus";
import { AES, enc } from "crypto-js";
import ObjectPath from "object-path";
import KTTopSearchbar from "@/view/components/DriveSearchBar.vue";
import DatePicker from "@/view/components/DatePicker";
import PDFDialog from "@/view/components/PDFDialog";

export default {
	name: "drive-listing",
	data() {
		return {
			tab: "detail",
			pdf_uuid: null,
			pdf_name: null,
			pdf_src: null,
			pdf_dialog: false,
			endpoint: null,
			selected: null,
			file_log: [],
			loading: false,
			log_loading: true,
			detail_loading: false,
			detail_drawer: false,
			lock_dialog: false,
			unlock_dialog: false,
			move_dialog: false,
			rename_dialog: false,
			delete_dialog: false,
			share_dialog: false,
			permanent_delete: 0,
			tag_dialog: false,
			show_context_menu: false,
			menu_x: null,
			menu_y: null,
			date_range: {
				start_date: null,
				end_date: null,
			},
			folder_unlock: {},
			folder_unlock_dialog: false,
			filter: {
				"linked-id": 0,
				"page-no": 1,
				"page-limit": 50,
				search: null,
				"sort-column": null,
				"sort-order": null,
				"start-date": null,
				"end-date": null,
			},
			pagination: {
				total_rows: null,
				total_pages: null,
				current_page: null,
				offset: null,
				page_limit: null,
			},
			rows: [],
			to_be_delete: {
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			},
			to_be_rename: {
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			},
			to_be_move: {
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			},
			to_be_lock: {
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			},
			to_be_unlock: {
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			},
			to_be_restore: {
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
					user: null,
				},
				deleted: {
					datetime: null,
					user: null,
				},
			},
			empty_bin_dialog: false,
			restore_dialog: false,
			default_context_menu: [],
			context_menu: [],
			temp_file: {},
			d_focus: false,
			file: {
				owner: {},
				shared: [],
				tags: [],
				description: null,
			},
			pre_select: {},
			open_timeout: null,
			open_timeout_limit: 200,
			route_timeout: null,
			route_timeout_limit: 500,
			linked_uuid: null,
			file_description: null,
			access_permission: [],
			file_tags: [],
		};
	},
	components: {
		"pdf-dialog": PDFDialog,
		"date-picker": DatePicker,
		"kt-top-search-bar": KTTopSearchbar,
		"show-value": ShowValue,
		"rename-dialog": RenameDialog,
		"move-dialog": MoveDialog,
		"lock-dialog": LockDialog,
		"unlock-dialog": UnlockDialog,
		"unlock-folder-dialog": UnlockFolderDialog,
		"share-with-people": ShareWithPeople,
		"delete-dialog": DeleteConfirmation,
		"restore-dialog": RestoreConfirmation,
		"empty-bin-dialog": EmptyBinConfirmation,
		"manage-tag-dialog": ManageTagDialog,
	},
	watch: {
		$route() {
			this.$store.dispatch(SET_BREADCRUMB, [
				{
					dashboard: true,
					disabled: false,
					id: 0,
					text: this.get_page_title(),
					uuid: null,
				},
			]);
			this.get_rows();
		},
	},
	methods: {
		download_folder(row) {
			this.$store
				.dispatch(GET, { url: `files/${row.uuid}/download` })
				.then((response) => {
					EventBus.$emit("init-download", response);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		get_page_title() {
			if (this.endpoint == "shared-with-me") {
				return "Shared with me";
			}

			if (this.endpoint == "recent") {
				return "Recent";
			}

			if (this.endpoint == "starred") {
				return "Starred";
			}

			if (this.endpoint == "bin") {
				return "Bin";
			}
		},
		redirect_ip(ip) {
			const url = `https://whatismyipaddress.com/ip/${ip}`;
			window.open(url, "_blank");
		},
		focus_description() {
			this.d_focus = true;
			this.$nextTick(() => {
				this.$refs["file-description"].$refs["input-text-area"].$refs["input"].focus();
			});
		},
		update_description() {
			this.d_focus = false;
			this.$store
				.dispatch(PATCH, {
					url: `files/${this.selected}/description`,
					data: { description: this.file_description },
				})
				.then((response) => {
					console.log({ response });
				})
				.catch((error) => {
					console.log(error);
				});
		},
		clear_filter(param) {
			this.date_range[param] = null;
		},
		filter_rows() {
			console.log("filter rows");
		},
		get_file_log() {
			this.log_loading = true;
			this.file_log = [];
			this.$store
				.dispatch(GET, { url: `files/${this.selected}/log` })
				.then((response) => {
					this.file_log = response;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.log_loading = false;
				});
		},
		get_file_detail() {
			const _this = this;
			_this.detail_loading = true;
			_this.access_permission = [];
			_this.file_tags = [];
			_this.file_description = null;
			_this.file = new Object({
				owner: {},
				shared: [],
				tags: [],
			});
			return new Promise((resolve, reject) => {
				if (!_this.selected) {
					reject("Invalid Request");
				} else {
					_this.$store
						.dispatch(GET, { url: `files/${_this.selected}/detail` })
						.then((response) => {
							_this.file = response;
							_this.file_description = cloneDeep(response.description);
							_this.access_permission = cloneDeep(response.shared);
							_this.file_tags = cloneDeep(response.tags);
							_this.$nextTick(() => {
								resolve(response);
							});
						})
						.catch((error) => {
							reject(error);
						})
						.finally(() => {
							_this.detail_loading = false;
						});
				}
			});
		},
		open_detail_drawer() {
			this.get_file_detail()
				.then(() => {
					this.detail_drawer = true;
				})
				.catch((error) => {
					console.log({ error });
				});
			/*clearTimeout(_this.timeout);
			_this.timeout = setTimeout(function() {
				
			}, _this.timeout_limit)*/
		},
		open_lock_dialog() {
			this.to_be_lock = find(this.rows, { uuid: this.selected });
			if (this.to_be_lock) {
				this.lock_dialog = true;
			}
		},
		open_unlock_dialog() {
			this.to_be_unlock = find(this.rows, { uuid: this.selected });
			if (this.to_be_unlock) {
				this.unlock_dialog = true;
			}
		},
		open_share_dialog() {
			this.get_file_detail()
				.then(() => {
					this.share_dialog = true;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		open_restore_dialog() {
			this.to_be_restore = find(this.rows, { uuid: this.selected });
			if (this.to_be_restore) {
				this.restore_dialog = true;
			}
		},
		restore_success() {
			this.to_be_restore = new Object({
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
					user: null,
				},
				deleted: {
					datetime: null,
					user: null,
				},
			});
			EventBus.$emit("refresh-listing", true);
			EventBus.$emit("refresh-storage", true);
		},
		empty_bin_success() {
			EventBus.$emit("refresh-listing", true);
			EventBus.$emit("refresh-storage", true);
		},
		open_delete_dialog() {
			this.to_be_delete = find(this.rows, { uuid: this.selected });
			if (this.to_be_delete) {
				this.delete_dialog = true;
			}
		},
		delete_success() {
			this.to_be_delete = new Object({
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			});
			EventBus.$emit("refresh-listing", true);
			EventBus.$emit("refresh-storage", true);
		},
		open_rename_dialog() {
			this.to_be_rename = find(this.rows, { uuid: this.selected });
			if (this.to_be_rename) {
				this.rename_dialog = true;
			}
		},
		rename_success() {
			this.to_be_rename = new Object({
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			});
			EventBus.$emit("refresh-listing", true);
			EventBus.$emit("refresh-storage", true);
		},
		move_success() {
			this.to_be_move = new Object({
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			});
			EventBus.$emit("refresh-listing", true);
			EventBus.$emit("refresh-storage", true);
		},
		lock_success() {
			this.to_be_lock = new Object({
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			});
			EventBus.$emit("refresh-listing", true);
			EventBus.$emit("refresh-storage", true);
		},
		unlock_success() {
			this.to_be_unlock = new Object({
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			});
			EventBus.$emit("refresh-listing", true);
			EventBus.$emit("refresh-storage", true);
		},
		folder_unlock_success(row) {
			const _this = this;
			_this.folder_unlock = new Object({});
			_this.folder_unlock_dialog = false;
			_this.$nextTick(() => {
				const folder_id = AES.encrypt(JSON.stringify(row.id), _this.$enc_key).toString();
				const folder_uuid = AES.encrypt(JSON.stringify(row.uuid), this.$enc_key).toString();
				_this.$router.push({
					name: this.endpoint,
					query: {
						t: new Date().getTime(),
						folder: folder_id,
						fuuid: folder_uuid,
					},
				});
			});
		},
		update_tags(tags) {
			this.$store
				.dispatch(PATCH, {
					url: `files/${this.selected}/tags`,
					data: { tags },
				})
				.then(() => {
					this.tag_dialog = false;
					EventBus.$emit("refresh-listing", true);
					EventBus.$emit("refresh-storage", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					// console.log({param})
				});
		},
		update_sharing(members) {
			this.$store
				.dispatch(PATCH, {
					url: `files/${this.selected}/sharing`,
					data: { members },
				})
				.then(() => {
					EventBus.$emit("refresh-listing", true);
					EventBus.$emit("refresh-storage", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					// console.log({param})
				});
		},
		update_color(param) {
			this.$store
				.dispatch(PATCH, {
					url: `files/${this.selected}/color`,
					data: { hex: param },
				})
				.then(() => {
					EventBus.$emit("refresh-listing", true);
					EventBus.$emit("refresh-storage", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					// console.log({param})
					this.show_context_menu = false;
				});
		},
		context_menu_dialog(e, row) {
			e.preventDefault();

			if (this.loading) {
				return false;
			}

			this.show_context_menu = false;
			this.menu_x = e.clientX;
			this.menu_y = e.clientY;

			this.$nextTick(() => {
				this.select_row(row);
				this.show_context_menu = true;
			});
		},
		init_context_menu(row) {
			this.context_menu = []; // this.default_context_menu;

			for (let i = 0; i < this.default_context_menu.length; i++) {
				if (this.default_context_menu[i].action == "add-to-starred") {
					if (!row.starred) {
						this.context_menu.push(this.default_context_menu[i]);
					}
				} else if (this.default_context_menu[i].action == "add-to-public") {
					if (!row.public) {
						this.context_menu.push(this.default_context_menu[i]);
					}
				} else if (this.default_context_menu[i].action == "remove-from-public") {
					if (row.public) {
						this.context_menu.push(this.default_context_menu[i]);
					}
				} else if (this.default_context_menu[i].action == "remove-from-starred") {
					if (row.starred) {
						this.context_menu.push(this.default_context_menu[i]);
					}
				} else if (this.default_context_menu[i].action == "lock") {
					if (row.type == "folder") {
						if (!row.locked) {
							this.context_menu.push(this.default_context_menu[i]);
						}
					}
				} else if (this.default_context_menu[i].action == "un-lock") {
					if (row.type == "folder") {
						if (row.locked) {
							this.context_menu.push(this.default_context_menu[i]);
						}
					}
				} else if (this.default_context_menu[i].action == "change-color") {
					if (row.type == "folder") {
						this.context_menu.push(this.default_context_menu[i]);
					}
				} else {
					this.context_menu.push(this.default_context_menu[i]);
				}
			}
		},
		do_menu_action(action) {
			switch (action) {
				case "share":
					this.open_share_dialog();
					break;
				case "manage-tags":
					this.tag_dialog = true;
					break;
				case "move":
					this.open_move_dialog();
					break;
				case "add-to-starred":
					this.update_starred();
					break;
				case "remove-from-starred":
					this.update_starred();
					break;
				case "add-to-public":
					this.update_public();
					break;
				case "remove-from-public":
					this.update_public();
					break;
				case "rename":
					this.open_rename_dialog();
					break;
				case "change-color":
					this.open_color_dialog();
					break;
				case "view-detail":
					this.open_detail_drawer();
					break;
				case "lock":
					this.open_lock_dialog();
					break;
				case "un-lock":
					this.open_unlock_dialog();
					break;
				case "download":
					this.download_file();
					break;
				case "restore":
					this.open_restore_dialog();
					break;
				case "remove":
					this.open_delete_dialog();
					break;
			}
		},
		download_file() {
			if (this.endpoint == "bin") {
				return false;
			}

			const to_be_download = find(this.rows, { uuid: this.selected });

			if (to_be_download) {
				if (to_be_download.type == "folder") {
					this.download_folder(to_be_download);
				} else {
					const token = JwtService.getToken();
					const url = `${this.$apiURL}files/${this.selected}/download?token=${token}`;
					window.open(url, "_blank");
				}
			}
		},
		update_starred() {
			this.$store
				.dispatch(PATCH, { url: `files/${this.selected}/starred` })
				.then(() => {
					EventBus.$emit("refresh-listing", true);
					EventBus.$emit("refresh-storage", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					// console.log({param})
				});
		},
		update_public() {
			this.$store
				.dispatch(PATCH, { url: `files/${this.selected}/public` })
				.then(() => {
					EventBus.$emit("refresh-listing", true);
					EventBus.$emit("refresh-storage", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					// console.log({param})
				});
		},
		open_move_dialog() {
			this.to_be_move = find(this.rows, { uuid: this.selected });
			if (this.to_be_move) {
				this.move_dialog = true;
			}
		},
		open_color_dialog() {
			/* .. */
		},
		select_row(row) {
			const _this = this;

			if (_this.loading) {
				return false;
			}

			_this.$nextTick(() => {
				_this.init_context_menu(row);
			});

			_this.temp_file = row;

			_this.selected = row.uuid;

			clearTimeout(_this.open_timeout);

			_this.open_timeout = setTimeout(function () {
				if (_this.detail_drawer) {
					_this.open_detail_drawer();
					if (_this.tab == "activity") {
						_this.get_file_log();
					}
				}
			}, _this.open_timeout_limit);
		},
		load_child_folder(param) {
			this.filter.search = null;
			const folder_id = ObjectPath.get(param, "query.folder");
			if (folder_id) {
				const decrypted = AES.decrypt(folder_id, this.$enc_key);
				const linked_id = toSafeInteger(JSON.parse(decrypted.toString(enc.Utf8)));
				if (linked_id) {
					this.filter["linked-id"] = linked_id;
					this.$nextTick(() => {
						this.get_rows();
					});
				}
			} else {
				this.filter["linked-id"] = 0;
				this.$nextTick(() => {
					this.get_rows();
				});
			}
		},
		breadcrumb_route(row) {
			if (this.loading || this.detail_loading || row?.disabled) {
				return false;
			}
			if (row.dashboard) {
				const route = {
					name: this.endpoint,
					query: {
						t: new Date().getTime(),
					},
				};

				this.$router.replace(route).then(() => {
					this.get_rows();
				});

				return false;
			}

			if (this.filter["linked-id"] == row.id) {
				return false;
			}

			this.filter["page-no"] = 1;
			const folder_id = AES.encrypt(JSON.stringify(row.id), this.$enc_key).toString();
			const folder_uuid = AES.encrypt(JSON.stringify(row.uuid), this.$enc_key).toString();
			this.$router
				.replace({
					name: this.endpoint,
					query: {
						t: new Date().getTime(),
						folder: folder_id,
						fuuid: folder_uuid,
					},
				})
				.then(() => {
					this.get_rows();
				});
		},
		download_pdf() {
			const token = JwtService.getToken();
			const url = `${this.$apiURL}files/${this.pdf_uuid}/download?token=${token}`;
			window.open(url, "_blank");
		},
		close_pdf() {
			this.pdf_uuid = null;
			this.pdf_name = null;
			this.pdf_src = null;
			this.pdf_dialog = false;
		},
		open_folder(row) {
			if (row.extension == "pdf") {
				const token = JwtService.getToken();

				this.loading = true;

				this.pdf_uuid = null;
				this.pdf_name = null;
				this.pdf_src = null;
				this.pdf_dialog = false;

				this.$store
					.dispatch(GET, { url: `files/${row.uuid}/download?token=${token}&temp=1` })
					.then(({ url }) => {
						this.pdf_uuid = row.uuid;
						this.pdf_name = row.name;
						this.pdf_src = url;
						this.pdf_dialog = true;
					})
					.catch((error) => {
						console.log({ error });
					})
					.finally(() => {
						this.loading = false;
					});

				return false;
			}

			if (this.endpoint == "bin") {
				return false;
			}

			clearTimeout(this.open_timeout);

			if (this.loading) {
				return false;
			}

			if (row.locked) {
				this.folder_unlock = row;
				this.folder_unlock_dialog = true;
				return false;
			}

			this.filter.search = null;

			let endpoint = this.endpoint;

			if (this.endpoint == "starred") {
				endpoint = "mine";
			}

			if (row.type == "folder") {
				this.filter["page-no"] = 1;
				const folder_id = AES.encrypt(JSON.stringify(row.id), this.$enc_key).toString();
				const folder_uuid = AES.encrypt(JSON.stringify(row.uuid), this.$enc_key).toString();
				this.$router
					.push({
						name: endpoint,
						query: {
							t: new Date().getTime(),
							folder: folder_id,
							fuuid: folder_uuid,
						},
					})
					.then(() => {
						this.get_rows();
					});
			} else {
				this.selected = row.uuid;
				this.$nextTick(() => {
					this.download_file();
				});
			}
		},
		update_pagination(param) {
			this.$router
				.push({
					name: this.endpoint,
					query: {
						...this.$route.query,
						t: new Date().getTime(),
						"page-no": param,
					},
				})
				.then(() => {
					this.get_rows();
				});
		},
		get_rows() {
			if (this.loading) {
				return false;
			}

			this.loading = true;

			this.detail_loading = true;

			this.detail_drawer = true;

			this.filter = {
				"linked-id": this.filter["linked-id"],
				"page-no": this.filter["page-no"],
				"page-limit": this.filter["page-limit"],
				search: this.filter["search"],
				"sort-column": this.filter["sort-column"],
				"sort-order": this.filter["sort-order"],
				"start-date": this.filter["start-date"],
				"end-date": this.filter["end-date"],
				...this.$route.query,
			};

			this.init_linked();

			this.filter["page-no"] = ObjectPath.get(this.$route, "query.page-no") || 1;
			this.filter["page-limit"] = ObjectPath.get(this.$route, "query.page-limit") || 20;
			this.filter["search"] = ObjectPath.get(this.$route, "query.search");
			this.filter["start-date"] = ObjectPath.get(this.$route, "query.start-date");
			this.filter["end-date"] = ObjectPath.get(this.$route, "query.end-date");

			const tag = ObjectPath.get(this.$route, "query.tag");
			if (tag) {
				this.filter["user"] = undefined;
				this.filter["tag"] = tag.substring(1);
			}

			const user = ObjectPath.get(this.$route, "query.user");
			if (user) {
				this.filter["tag"] = undefined;
				this.filter["user"] = user.substring(1);
			}

			if (this.filter["search"]) {
				this.filter["user"] = undefined;
				this.filter["tag"] = undefined;
			}

			this.$store
				.dispatch(QUERY, { url: `files/${this.endpoint}`, data: this.filter })
				.then((response) => {
					this.$store.dispatch(SET_BREADCRUMB, response["breadcrumb"]);
					localStorage.setItem("prev-id", response["linked-id"]);
					this.rows = response.rows;
					this.pre_select = ObjectPath.get(response, "rows.0", null);
					this.filter["linked-id"] = response["linked-id"];
					this.default_context_menu = response["context-menu"];
					const last_breadcrumb = last(response["breadcrumb"]);
					this.$store.dispatch(SEARCH_TITLE, last_breadcrumb.text);
					this.pagination.total_rows = response["total"];
					this.pagination.total_pages = Math.ceil(response["total"] / response["page-limit"]);
					this.pagination.current_page = response["page-no"];
					this.pagination.offset = response["offset"];
					this.pagination.page_limit = response["page-limit"];
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					if (this.rows.length < 1) {
						this.detail_loading = false;
						this.detail_drawer = false;
					}
					this.reset_all();
					this.$nextTick(() => {
						let scrollableDiv = document.getElementById("scrollable-div");
						if (scrollableDiv) {
							scrollableDiv.scroll({ top: 0, behavior: "smooth" });
						}
					});
				});
		},
		reset_all() {
			const _this = this;
			_this.tab = "detail";
			_this.selected = null;
			_this.loading = false;
			_this.log_loading = true;
			_this.move_dialog = false;
			_this.rename_dialog = false;
			_this.delete_dialog = false;
			_this.share_dialog = false;
			_this.permanent_delete = 0;
			_this.show_context_menu = false;
			_this.menu_x = null;
			_this.menu_y = null;

			_this.to_be_delete = {
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			};

			_this.to_be_rename = {
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			};

			_this.to_be_move = {
				uuid: null,
				type: null,
				name: null,
				created: {
					datetime: null,
				},
			};

			_this.access_permission = [];
			_this.file_description = null;

			_this.file = {
				owner: {},
				shared: [],
			};

			const prev_folder_id = toSafeInteger(localStorage.getItem("prev-id"));
			if (prev_folder_id) {
				const prev_folder = find(_this.rows, { id: prev_folder_id });
				if (prev_folder) {
					_this.selected = prev_folder.uuid;
					_this.init_context_menu(prev_folder);
				} else {
					if (_this.pre_select) {
						_this.selected = _this.pre_select.uuid;
						_this.init_context_menu(_this.pre_select);
					} else {
						_this.selected = _this.linked_uuid;
					}
				}
			} else {
				if (_this.pre_select) {
					_this.selected = _this.pre_select.uuid;
					_this.init_context_menu(_this.pre_select);
				} else {
					_this.selected = _this.linked_uuid;
				}
			}

			_this.$nextTick(() => {
				if (_this.selected) {
					_this.open_detail_drawer();
				}
			});
		},
		init_linked() {
			this.linked_uuid = null;
			const fuuid = ObjectPath.get(this.$route, "query.fuuid", null);
			if (fuuid) {
				const decrypted = AES.decrypt(fuuid, this.$enc_key);
				const linked_uuid = toString(JSON.parse(decrypted.toString(enc.Utf8)));
				if (linked_uuid) {
					this.linked_uuid = linked_uuid;
				}
			}

			this.filter["linked-id"] = 0;
			const folder_id = ObjectPath.get(this.$route, "query.folder", null);
			if (folder_id) {
				const decrypted = AES.decrypt(folder_id, this.$enc_key);
				const linked_id = toSafeInteger(JSON.parse(decrypted.toString(enc.Utf8)));
				if (linked_id) {
					this.filter["linked-id"] = linked_id;
				}
			}
		},
	},
	beforeDestroy() {
		EventBus.$off("start-search");
		EventBus.$off("date-search");
		EventBus.$off("refresh-listing");
		EventBus.$emit("show-search-setting", false);
		this.$store.dispatch(SEARCH_ENABLED, false);
		this.$store.dispatch(SEARCH_TITLE, null);
	},
	beforeMount() {
		this.init_linked();
	},
	mounted() {
		const _this = this;

		_this.get_rows();

		_this.$store.dispatch(SEARCH_ENABLED, true);
		_this.$store.dispatch(SEARCH_TITLE, "Drive");
		_this.$store.dispatch(SET_BREADCRUMB, [
			{
				dashboard: true,
				disabled: false,
				id: 0,
				text: this.get_page_title(),
				uuid: null,
			},
		]);

		EventBus.$on("refresh-listing", () => {
			_this.$nextTick(() => {
				_this.get_rows();
			});
		});

		EventBus.$emit("show-search-setting", true);

		EventBus.$on("date-search", (param) => {
			_this.filter["start-date"] = head(param);
			_this.filter["end-date"] = last(param);
			_this.get_rows();
		});

		EventBus.$on("start-search", (param) => {
			_this.filter.search = param;
			_this.$nextTick(() => {
				_this.get_rows();
			});
		});
	},
	computed: {
		pageWidth() {
			if (this.$vuetify.breakpoint.xl) {
				return "450px";
			}
			return "350px";
		},
	},
};
